import "./App.css";
import { useState, useEffect } from "react";

import BaseMap from "./components/map";
import SearchBox from "./components/searchBox";
import AddFiltersButton from "./components/addFiltersButton";
import AdBanner from "./components/adBanner";
import OffcanvasMenu from "./components/offCanvasMenu";
import FeedbackModal from "./components/feedbackForm";

import stationSample from "./constants/station_sample.json";

function App() {
  const [routeStart, setRouteStart] = useState("");
  const [routeEnd, setRouteEnd] = useState("");
  const [stations, setStations] = useState([]);
  const [filteredStations, setFilteredStations] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleFeedbackShow = () => setShowFeedback(true);
  const handleFeedbackClose = () => setShowFeedback(false);

  // Set stations data once when the component mounts
  useEffect(() => {
    setStations(stationSample);
    setSelectedFilters([
      "Extra",
      "Moto",
      "Roadchef",
      "Welcome Break",
    ]);
  }, []);

  useEffect(() => {
    const filterStations = async () => {
      const filters = selectedFilters.filter(item => item !== "All Locations");

      if (filters.length > 0) {
        const keptStations = [];
        for (let i=0; i<stations.length; i++) {
          let keepStation = false;
          for (let j=0; j<selectedFilters.length; j++) {
            if ( 
              stations[i].restaurants.includes(filters[j]) |
              stations[i].provider.includes(filters[j])
          ) {
              keepStation = true;
            }
          }
          if ( keepStation ) {
            keptStations.push(stations[i]);
          }
        }
        setFilteredStations(keptStations);
      } else {
        setFilteredStations(stationSample);
      }
    };
    filterStations();
  }, [selectedFilters]);

  return (
    <div>
      <BaseMap
        position={[55, -2.0]}
        zoom={6}
        stations={filteredStations}
        routeStart={routeStart}
        routeEnd={routeEnd}
      />
      <SearchBox setRouteStart={setRouteStart} setRouteEnd={setRouteEnd} />
      <AddFiltersButton 
        setShowOffCanvas={setShowOffCanvas}
      />
      <OffcanvasMenu 
        showOffCanvas={showOffCanvas} 
        setShowOffCanvas={setShowOffCanvas} 
        handleFeedbackShow={handleFeedbackShow} 
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <FeedbackModal show={showFeedback} handleClose={handleFeedbackClose} />
      <AdBanner/>
    </div>
  );
}

export default App;

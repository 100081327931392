import { Button } from "react-bootstrap";

function FeedbackButton({ handleFeedbackShow }) {
  return (
    <div className="feedback-button-div">
      <Button onClick={handleFeedbackShow} className="feedback-button">
        Give Feedback
      </Button>
    </div>
  );
}

export default FeedbackButton;

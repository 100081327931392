import { useMap } from "react-leaflet";
import { logger } from "../logger.js";
import locationArrow from "../constants/icons/location-arrow.png";


function CurrentLocationButton({ setCurrentLatLon }) {
    const map = useMap();


    // get current location when search box button clicked
    async function getCurrentLocation() {
        const logMessage = "Current location button clicked";
        logger({ logID: "getCurrentLocation", text: logMessage });
        if (navigator.geolocation) {
            // set search box and buttons as disabled until response is returned
            // get response
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log("Geolocation not supported");
        }
    }

    async function success(position)  {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setCurrentLatLon([latitude, longitude]);
        const center = [latitude, longitude];
        const zoom = 10;
        map.flyTo(center, zoom);

        const logMessage = (
            `Success getting current location. latitude: ${latitude} ` +
            `longitude: ${longitude}`
        );
        console.log(logMessage);
        logger({ logID: "currentLocationButton", text: logMessage });
    }

    async function error()  {
        alert("Unable to get current location");

        const logMessage = (
            `Error getting current location.`
        );
        console.log(logMessage);
        logger({ logID: "currentLocationButton", text: logMessage });
    }


    return (
      <button 
        className="current-location-arrow-button" 
        style={{ background: 'none', border: 'none', padding: 0 }}
        onClick={getCurrentLocation}
        >

        <img 
            src={locationArrow} 
            className="current-location-arrow">
        </img>
      </button>
    );

}

export default CurrentLocationButton;
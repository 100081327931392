import stripeLogo from "../constants/logos/donate.png";

function StripeButton() {
  const handleClick = () => {
    window.location.href = "https://buy.stripe.com/00g6qHcZ8eH98i4aEE";
  };

  return (
    <div className="stripe-button-div">
      <button className="stripe-button" onClick={handleClick}>
        <img className="stripe-button-image" src={stripeLogo} alt="donate" />
      </button>
    </div>
  );
}

export default StripeButton;

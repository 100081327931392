import { useState, useEffect } from "react";
import "../App.css";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  useMap,
  useMapEvent,
  Marker
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { FaDotCircle } from "react-icons/fa";
import ReactDOMServer from "react-dom/server";


import StationMarker from "./marker.js";
import CurrentLocationArrow from "./currentLocationArrow.js";
import CurrentLocationButton from "./currentLocationButton";
import {
  SubmitRoute,
  ConvertTextToCoordinates,
} from "../services/openRouteService.js";

function SetMapCenterAndZoom({ georoute }) {
  const map = useMap();

  // autozoom to plotted route
  useEffect(() => {
    if (georoute && georoute.bbox) {
      const bounds = L.latLngBounds([
        [georoute.bbox[1], georoute.bbox[0]], // Southwest corner
        [georoute.bbox[3], georoute.bbox[2]], // Northeast corner
      ]);
      const topPaddingLatDiff = (bounds.getNorth() - bounds.getSouth()) * 0.2;
      const bottomPaddingLatDiff =
        (bounds.getNorth() - bounds.getSouth()) * 0.05;

      const paddedBounds = L.latLngBounds([
        [bounds.getSouth() - bottomPaddingLatDiff, bounds.getWest()],
        [bounds.getNorth() + topPaddingLatDiff, bounds.getEast()],
      ]);

      const center = paddedBounds.getCenter();
      const zoom = map.getBoundsZoom(paddedBounds);

      map.flyTo(center, zoom);
    }
  }, [map, georoute]);

  return null;
}

function BaseMap({ position, zoom, stations, routeStart, routeEnd }) {
  const [route, setRoute] = useState(null);
  const [startLatLon, setStartLatLon] = useState([]);
  const [currentLatLon, setCurrentLatLon] = useState([]);

  // handles whether we should call the autocomplete api or not
  const [routesEnabled, setRoutesEnabled] = useState(true);
  const [routesEnabledTimer, setRoutesEnabledTimer] = useState(Date.now());

  // get route for plotting
  useEffect(() => {
    const fetchRouteData = async () => {
      if (routeStart && routeEnd) {
        try {
          const georoute = await SubmitRoute(routeStart, routeEnd);
          setRoute(georoute);
          const startLL = georoute["features"][0]["geometry"]["coordinates"][0];
          setCurrentLatLon([startLL[1], startLL[0]]);
        } catch (error) {
          console.error("Error fetching route:", error);
          setRoute(null);
        }
      }
    };

    fetchRouteData();
  }, [routeStart, routeEnd]);

  // get start lat and lon values
  useEffect(() => {
    const fetchStartLatLon = async () => {
      if (routeStart) {
        const startLL = await ConvertTextToCoordinates(routeStart);
        if (startLL === "disable") {
          console.log("disabling routes for 24 hrs...");
          setStartLatLon([]);
          setRoutesEnabled(false);
          setRoutesEnabledTimer(Date.now());
          disableRoutes();
          alert(
            "Route plotting is currently unavailable. Please try again later."
          );
        } else if (startLL) {
          setStartLatLon(startLL);
        } else {
          setStartLatLon([]);
          alert(
            "Error reading start address. Please ensure that it is " +
              "typed correcty and that is an address in the UK."
          );
        }
      }
    };
    fetchStartLatLon();
  }, [routeStart]);

  // routesEnabled handler
  useEffect(() => {
    const checkInterval = 10000; // milliseconds
    const enableAfter = 86400000; // 24 hours

    const autofillEnabledHandler = async () => {
      if (!routesEnabled) {
        // if the interval time has passed, re-enable the routes function
        if (Date.now() - routesEnabledTimer > enableAfter) {
          console.log(
            "re-enabling routes after ",
            Date.now() - routesEnabledTimer,
            " seconds..."
          );
          setRoutesEnabled(true);
          enableRoutes();
        }
      }
    };
    // Set a timeout to delay the execution of the async function
    const timeoutId = setInterval(() => {
      autofillEnabledHandler();
    }, checkInterval);
    return () => {
      clearInterval(timeoutId);
    };
  }, [routesEnabled, setRoutesEnabled, routesEnabledTimer]);

  // disable routes
  function disableRoutes() {
    document.getElementById("search-box-start").style.display = "none";
    document.getElementById("search-box-end").style.display = "none";
    // document.getElementById('search-box').style.display = 'none';
    document.getElementById("current-location-button").style.display = "none";
    document.getElementById("swap-start-end-button").style.display = "none";
  }

  // enable routes
  function enableRoutes() {
    document.getElementById("search-box-start").style.display = "block";
    document.getElementById("search-box-end").style.display = "block";
    // document.getElementById('search-box').style.display = 'block';
    document.getElementById("current-location-button").style.display = "block";
    document.getElementById("swap-start-end-button").style.display = "block";
  }

  return (
    <MapContainer
      className="base-map"
      center={position}
      zoom={zoom}
      scrollWheelZoom={true}
      zoomDelta={0.1}
      zoomControl={false}
    >
      <TileLayer
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <SetMapCenterAndZoom
        key={JSON.stringify(route) + "SetMapCenterAndZoom"}
        georoute={route}
      />
      {stations.length > 0 &&
        stations.map((station, index) => (
          <StationMarker
            id="station-marker"
            key={index}
            station={station}
            startLatLon={startLatLon}
          />
        ))}
      {route && (
        <GeoJSON key={JSON.stringify(route) + "GeoJSON"} data={route} />
      )}
      {/* {route && (
        <Marker 
          position={[startLatLon[1], startLatLon[0]]} 
          icon={L.divIcon({
            className: "route-start-marker",
            html: ReactDOMServer.renderToString(<FaDotCircle />),
          })}
        >
        </Marker>
      )} */}
      <CurrentLocationButton 
        setCurrentLatLon={setCurrentLatLon}
      />
      <CurrentLocationArrow 
        currentLatLon={currentLatLon}
      />
    </MapContainer>
  );
}

export default BaseMap;

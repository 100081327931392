import React, { useEffect } from 'react';

const AdBanner = () => {
  // useEffect(() => {
  //   // Reload ads after component is mounted
  //   (window.adsbygoogle = window.adsbygoogle || []).push({});
  // }, []);

  return (
    <div>


    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6303319247658324"
        crossOrigin="anonymous"></script>
        <ins class="adsbygoogle"
            style={{"display":"block"}}
            data-ad-client="ca-pub-6303319247658324"
            data-ad-slot="2651717754"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </script>

    </div>
  );
};

export default AdBanner;
export function logger({ logID, text }) {
  fetch("https://serviceme.uk/api/logger", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ logID: logID, text: text }),
  }).catch((error) => {
    console.log("Failed to send log to server:", error);
  });
}

import { useState, useEffect, useRef } from "react";
import { FaLocationCrosshairs, FaLocationDot } from "react-icons/fa6";
import { IoSwapVerticalOutline } from "react-icons/io5";

import {
  AutoFill,
  ConvertCoordinatesToText,
} from "../services/openRouteService.js";
import { logger } from "../logger.js"

function SearchBox({ setRouteStart, setRouteEnd }) {
  const [startValue, setStartValue] = useState("");
  const [endValue, setEndValue] = useState("");

  const [startSuggestions, setStartSuggestions] = useState([]);
  const [endSuggestions, setEndSuggestions] = useState([]);

  const [activeElementId, setActiveElementId] = useState("");

  // handles whether we should call the autocomplete api or not
  const [suggestionsEnabled, setSuggestionsEnabled] = useState(true);
  const [suggestionsEnabledTimer, setSuggestionsEnabledTimer] = useState(
    Date.now()
  );

  // submit start and end location values
  const handleSubmit = (event) => {
    event.preventDefault();
    setRouteStart(startValue);
    setRouteEnd(endValue);
  };

  async function enableStartSearch() {
    document.getElementById("search-box-start").disabled = false;
    document.getElementById("swap-start-end-button").style.pointerEvents =
      "auto";
    document.getElementById("current-location-button").style.pointerEvents =
      "auto";
  }

  async function disableStartSearch() {
    document.getElementById("search-box-start").disabled = true;
    document.getElementById("swap-start-end-button").style.pointerEvents =
      "none";
    document.getElementById("current-location-button").style.pointerEvents =
      "none";
  }

  // get current location when search box button clicked
  async function getCurrentLocation() {
    const logMessage = "Current location button clicked";
    logger({ logID: "getCurrentLocation", text: logMessage });
    if (navigator.geolocation) {
      // set search box and buttons as disabled until response is returned
      disableStartSearch();
      // get response
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }

  async function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const address = await ConvertCoordinatesToText(longitude, latitude);
    setStartValue(address);
    // if both start and end value are present, submit the route
    enableStartSearch();
    const logMessage = (
      `Success getting current location. latitude: ${latitude} ` +
      `longitude: ${longitude} address: ${address}`
    );
    console.log(logMessage);
    logger({ logID: "getCurrentLocation", text: logMessage });
    if (endValue) {
      setRouteStart(address);
      setRouteEnd(endValue);
    }
  }

  function error() {
    const logMessage = "ERROR: Unable to retrieve location";
    console.log(logMessage);
    logger({ logID: "getCurrentLocation", text: logMessage });
    // set search box and buttons as disabled until response is returned
    enableStartSearch();
  }

  // swap start/end locations
  async function swapStartEndLocations() {
    const oldStartValue = startValue;
    const oldEndValue = endValue;
    setStartValue(oldEndValue);
    setEndValue(oldStartValue);
    // if both start and end value are present, submit the route
    if (startValue && endValue) {
      setRouteStart(startValue);
      setRouteEnd(endValue);
    }
  }

  // autofillEnabled handler
  useEffect(() => {
    const checkInterval = 10000; // milliseconds
    const enableAfter = 86400000; // 24 hours

    const autofillEnabledHandler = async () => {
      if (!suggestionsEnabled) {
        // if the interval time has passed, re-enable the suggestions function
        if (Date.now() - suggestionsEnabledTimer > enableAfter) {
          console.log(
            "re-enabling suggestions after ",
            Date.now() - suggestionsEnabledTimer,
            " seconds..."
          );
          setSuggestionsEnabled(true);
        }
      }
    };
    // Set a timeout to delay the execution of the async function
    const timeoutId = setInterval(() => {
      autofillEnabledHandler();
    }, checkInterval);
    return () => {
      clearInterval(timeoutId);
    };
  }, [
    startValue,
    suggestionsEnabled,
    setSuggestionsEnabled,
    suggestionsEnabledTimer,
  ]);

  useEffect(() => {
    const changeActiveElement = () => {
      setActiveElementId(document.activeElement.id);
    };
    // Add event listener for focus changes
    document.addEventListener("focusin", changeActiveElement);
    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("focusin", changeActiveElement);
    };
  }, []);

  // fetch start location suggestions
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (startValue && suggestionsEnabled) {
        try {
          const response = await AutoFill(startValue);
          if (response === "disable") {
            setSuggestionsEnabled(false);
            setSuggestionsEnabledTimer(Date.now());
            console.log("suggestions disabled...");
            return [];
          } else {
            setStartSuggestions(response);
          }
          // setStartSuggestions([
          //   "sheffield",
          //   "london",
          //   "edinburgh",
          //   "liverpool",
          // ]);
        } catch (error) {
          console.error("Error fetching startAddress suggestions:", error);
        }
      } else {
        setStartSuggestions([]);
      }
    };

    if (activeElementId !== "search-box-start") {
      setStartSuggestions([]);
      return;
    }

    // Set a timeout to delay the execution of the async function
    const timeoutId = setTimeout(() => {
      fetchSuggestions();
    }, 750); // 2000ms = 2 seconds
    return () => {
      clearTimeout(timeoutId);
    };
  }, [startValue, suggestionsEnabled, activeElementId]);

  // fetch end location suggestions
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (endValue && suggestionsEnabled) {
        try {
          const response = await AutoFill(endValue);
          if (response === "disable") {
            setSuggestionsEnabled(false);
            setSuggestionsEnabledTimer(Date.now());
            console.log("suggestions disabled...");
            return [];
          } else {
            setEndSuggestions(response);
          }
        } catch (error) {
          console.error("Error fetching endAddress suggestions:", error);
        }
        // setStartSuggestions([
        //   "sheffield",
        //   "london",
        //   "edinburgh",
        //   "liverpool",
        // ]);
      } else {
        setEndSuggestions([]);
      }
    };

    if (activeElementId !== "search-box-end") {
      setEndSuggestions([]);
      return;
    }

    // Set a timeout to delay the execution of the async function
    const timeoutId = setTimeout(() => {
      fetchSuggestions();
    }, 750); // 2000ms = 2 seconds
    return () => {
      clearTimeout(timeoutId);
    };
  }, [endValue, suggestionsEnabled, activeElementId]);

  // set clicked suggestion to startValue
  const handleStartSuggestionClick = async (item) => {
    setStartValue(item);
    setStartSuggestions([]);
    // active element doesn't automatically change to this on suggestions click, so do it manually
    setActiveElementId('start-suggestions-list');
    // if we have both a start and end value, submit the route
    if (startValue && endValue) {
      setRouteStart(item);
      setRouteEnd(endValue);
    }
  };

  // set clicked suggestion to startValue
  const handleEndSuggestionClick = async (item) => {
    setEndValue(item);
    setEndSuggestions([]);
    // active element doesn't automatically change to this on suggestions click, so do it manually
    setActiveElementId('end-suggestions-list');
    // if we have both a start and end value, submit the route
    if (startValue && endValue) {
      setRouteStart(startValue);
      setRouteEnd(item);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          id="search-box-start"
          className="search-box search-box-start"
          placeholder="Start"
          onChange={(e) => setStartValue(e.target.value)}
          value={startValue}
          // onClick={showStartSuggestionsList}
          autocomplete="off"
        />
        <IoSwapVerticalOutline
          id="swap-start-end-button"
          className="swap-start-end-button"
          onClick={swapStartEndLocations}
        />
        <FaLocationCrosshairs
          id="current-location-button"
          className="current-location-button"
          onClick={getCurrentLocation}
        />
        {startSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {startSuggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleStartSuggestionClick(suggestion)}
              >
                <FaLocationDot className="suggestion-location-marker" />
                <div className="suggestion-location-text">{suggestion}</div>
              </li>
            ))}
          </ul>
        )}
        <input
          id="search-box-end"
          className="search-box search-box-end"
          placeholder="End"
          onChange={(e) => setEndValue(e.target.value)}
          value={endValue}
          // onClick={showEndSuggestionsList}
          autocomplete="off"
        />
        {endSuggestions.length > 0 && (
          <ul className="suggestions-list end-suggestions-list">
            {endSuggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleEndSuggestionClick(suggestion)}
              >
                <FaLocationDot className="suggestion-location-marker" />
                <div className="suggestion-location-text">{suggestion}</div>
              </li>
            ))}
          </ul>
        )}
        <button type="submit" style={{ display: "none" }}></button>
      </form>
    </div>
  );
}

export default SearchBox;
